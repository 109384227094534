<template lang="pug">
  .redirect
</template>

<script>
export default {
  mounted() {
    window.location.replace(this.$route.params.path);
  }
};
</script>
